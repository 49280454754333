var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        fullscreen: "",
        "hide-overlay": "",
        "background-color": "primary",
        transition: "dialog-bottom-transition",
      },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { flat: "", tile: "" } },
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-row",
                {
                  staticClass: "no-gutters",
                  staticStyle: { height: "90vh" },
                  attrs: { align: "center", justify: "center" },
                },
                [
                  _c(
                    "v-col",
                    {
                      staticClass:
                        "fill-height d-flex flex-column justify-center align-center my-auto",
                      attrs: { cols: "12" },
                    },
                    [
                      _c(
                        "div",
                        [
                          _c("v-img", {
                            staticClass: "mx-auto my-2",
                            attrs: {
                              height: "auto",
                              src: require("@/assets/images/" +
                                _vm.errorJson[_vm.errorCode].src),
                              "max-width": _vm.$vuetify.breakpoint.smAndDown
                                ? "140"
                                : "180",
                              "min-width": _vm.$vuetify.breakpoint.smAndDown
                                ? "130"
                                : "170",
                            },
                          }),
                          _c("v-row", { attrs: { "no-gutters": "" } }, [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "text-h5 font-weight-bold grey--text text--darken-4 mx-auto my-2",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.errorJson[_vm.errorCode].heading
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                          _c("v-row", { staticClass: "my-2" }, [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "subtitle-2 font-weight-medium grey--text text--darken-2 mx-auto text-center",
                                staticStyle: { display: "block" },
                              },
                              [_vm._v(_vm._s(_vm.errorMessage) + " ")]
                            ),
                          ]),
                          _c(
                            "v-row",
                            { attrs: { justify: "center" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "7" } },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "mt-4 mb-1",
                                      attrs: { justify: "center" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "subtitle-2 font-weight-regular grey--text text--darken-2 mx-auto text-center",
                                          staticStyle: {
                                            "word-break": "keep-all",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " We're sorry, but the requested feature is currently disabled. Our system administrators have identified misuse or violation of our usage policies, which has led to the temporary suspension of this functionality. "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "mt-4 mb-1",
                                      attrs: { justify: "center" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "subtitle-2 font-weight-regular grey--text text--darken-2 mx-auto text-center",
                                          staticStyle: {
                                            "word-break": "keep-all",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " If you believe this action is in error or have any questions, please contact our support team at "
                                          ),
                                          _c(
                                            "a",
                                            { staticClass: "primary--text" },
                                            [_vm._v("support@norfielddp.com")]
                                          ),
                                          _vm._v(
                                            ". Be sure to provide details about your use case, and our team will investigate the matter promptly. "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "mt-4 mb-1",
                                      attrs: { justify: "center" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "subtitle-2 font-weight-regular grey--text text--darken-2 mx-auto text-center",
                                        },
                                        [
                                          _vm._v(
                                            " We appreciate your understanding and cooperation in maintaining a secure and enjoyable experience for all users. "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "mt-4",
                                      attrs: { justify: "start" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "subtitle-2 font-weight-regular grey--text text--darken-2",
                                        },
                                        [_vm._v(" Thank you, ")]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "mb-1",
                                      attrs: { justify: "start" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "subtitle-2 font-weight-regular grey--text text--darken-2",
                                        },
                                        [_vm._v(" Norfield Team ")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { staticClass: "my-10" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "primary text-none rounded-lg elevation-0 mx-auto",
                                  attrs: { width: "140", depressed: "" },
                                  on: { click: _vm.goToTickets },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "subtitle-2 font-weight-regular mx-auto my-2",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("goToTickets")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }